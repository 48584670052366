import React, { useState } from 'react';
import c from 'classnames';
import { Link } from 'react-router-dom';

import { API_BASE_PATH } from '../../config/api';
import useStore from '../../stores';

import PageListView from '../../components/Page/PageListView';

import { formatDate } from '../../lib/utils/formatDate';
import { getKitStatusColor } from '../../pages/Kits';

import FulfillmentKit from '../../components/FulfillmentKit/FulfillmentKit';

const columns = filter => {
  if (filter === 'pending') {
    return [{ Header: ' ', accessor: '', disable_sort: true }];
  }

  return [
    { Header: 'id', accessor: 'id' },
    { Header: 'Order Number', accessor: 'order_number' },
    {
      Header: 'Total Kits',
      accessor: row => row.kits.length,
      id: 'total_kits',
      disable_sort: true,
    },
    {
      Header: 'Status',
      accessor: 'status',
      Cell: ({ value }) => (
        <div>
          <span
            className={c('text-sm rounded-2xl p-2', getKitStatusColor(value))}
          >
            {value}
          </span>
        </div>
      ),
    },
    {
      Header: 'Created At',
      id: 'created_at',
      accessor: row => formatDate(row.created_at),
    },
  ];
};

const PendingFulfillmentTableRow = ({ row, row_index, filter, removeRow }) => {
  const [downloadedLabels, setDownloadedLabels] = useState([]);
  const [error, setError] = useState();
  const [updatedFulfillmentId, setUpdatedFulfillmentId] = useState();
  const token = useStore(state => state.admin.token);
  const setAdmin = useStore(state => state.admin.setAdmin);
  const [completedFulfillmentKits, setCompletedFulfillmentKits] = useState([]);

  const updateFulfillmentStatus = async (id, status) => {
    const res = await fetch(`${API_BASE_PATH}/ops/admins/fulfillments/${id}`, {
      method: 'PUT',
      headers: {
        Authorization: `Bearer ${token}`,
        'x-access-token': token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        status,
      }),
    });
    if (res.status === 200) {
      setUpdatedFulfillmentId(id);
      removeRow(row_index);
    } else if (res.status === 401) {
      await setAdmin(undefined, undefined);
    } else if (res.status === 500 || res.status === 404) {
      setError(
        'There was a problem updating the fulfillment, please try again later or contact support.',
      );
    } else {
      const { error } = await res.json();
      setError(error);
    }
  };

  const updateCompletedFulfillmentKits = async fulfillmentKitId => {
    const newList = Array.from(completedFulfillmentKits);
    if (!newList.includes(fulfillmentKitId)) {
      newList.push(fulfillmentKitId);
    }
    setCompletedFulfillmentKits(newList);
  };

  const saveKitToFulfillment = async (id, fulfillmentKitId, kitId) => {
    const res = await fetch(
      `${API_BASE_PATH}/ops/admins/fulfillments/${id}/fulfillments-kits/${fulfillmentKitId}/`,
      {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
          'x-access-token': token,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ kit_id: kitId }),
      },
    );
    if (res.status === 200) {
      setUpdatedFulfillmentId(true);
    } else if (res.status === 401) {
      await setAdmin(undefined, undefined);
    } else if (res.status === 500 || res.status === 404) {
      setError(
        'There was a problem updating the fulfillment, please try again later or contact support.',
      );
    } else {
      const { error } = await res.json();
      setError(error);
    }
    updateCompletedFulfillmentKits(fulfillmentKitId);
  };

  const updateDownloadedLabels = async label => {
    const newList = Array.from(downloadedLabels);
    if (!newList.includes(label)) {
      newList.push(label);
    }
    setDownloadedLabels(newList);
  };
  const checkAllLabelsDownloaded = fulfillment => {
    if (!downloadedLabels.includes(fulfillment.order_id)) {
      return true;
    }

    for (let kit of fulfillment.kits) {
      if (
        kit.return_label_url?.length > 0 &&
        kit.return_label_url !== 'international' &&
        !downloadedLabels.includes(kit.return_label_url)
      ) {
        return true;
      }
    }

    return false;
  };

  const checkFulfillmentKitsCompleted = fulfillment => {
    for (let fulfillmentKit of fulfillment.fulfillments_kits) {
      if (
        !fulfillmentKit.kit_id ||
        !completedFulfillmentKits.includes(fulfillmentKit.id)
      ) {
        return false;
      }
    }

    return true;
  };

  if (filter !== 'pending') return null;

  return (
    <>
      {updatedFulfillmentId ? (
        <div className='bg-green-50 outline-none shadow-md rounded-md border border-solid border-green-500 mt-4'>
          <div className='flex flex-col p-6'>
            <div className='font-medium'>
              Fulfillment {updatedFulfillmentId} successfully updated
            </div>
          </div>
        </div>
      ) : null}
      {error ? (
        <div className='bg-pink-50 outline-none shadow-md rounded-md border border-solid border-green-500 mt-4'>
          <div className='flex flex-col p-6'>
            <div className='font-medium'>{error}</div>
          </div>
        </div>
      ) : null}
      {!row.original.fulfillments_kits ||
      row.original.fulfillments_kits.length === 0 ? (
        ''
      ) : (
        <div className='flex justify-between break-words py-4 px-2 text-left border-t border-solid border-gray-300'>
          <div>
            <span className='text-sm'>
              Fulfillment {row.original.id}, pack this:
            </span>
            <ul style={{ listStyleType: 'square' }} className='pl-6'>
              {row.original.is_priority === 1 && (
                <li>
                  <span className='text-md font-serif font-semibold'>
                    Priority: Programs
                  </span>
                </li>
              )}
              {row.original.is_priority === 2 && (
                <li>
                  <span className='text-md font-serif font-semibold'>
                    Priority: Pro Kits
                  </span>
                </li>
              )}
              {row.original.is_priority === 3 && (
                <li>
                  <span className='text-md font-serif font-semibold'>
                    Priority: Shipping
                  </span>
                </li>
              )}
              {row.original.membership_plan && (
                <li>
                  <span className='text-md font-serif font-semibold'>
                    Membership Plan: {row.original.membership_plan}
                  </span>
                </li>
              )}
              <li>
                <span className='text-sm'>
                  {`Order ${row.original.order_id}, `}
                  <Link
                    to={{
                      pathname: `https://tinyhealth.myshopify.com/admin/orders/${row.original.order_id}`,
                    }}
                    onClick={() =>
                      updateDownloadedLabels(row.original.order_id)
                    }
                    target='_blank'
                    rel='noopener noreferrer'
                    className={
                      downloadedLabels.includes(row.original.order_id)
                        ? 'underline text-green-600'
                        : 'underline text-blue-600'
                    }
                  >
                    {downloadedLabels.includes(row.original.order_id)
                      ? 'Downloaded'
                      : 'Download Outbound Label'}
                  </Link>
                </span>
                <ul className='ml-4'>
                  <li>
                    <span className='text-sm'>
                      Order number: {row.original.order_number}
                    </span>
                  </li>
                  <li>
                    <span className='text-sm'>
                      Tracking number to user:{' '}
                      {row.original.tracking_number_to_user}
                    </span>
                  </li>
                </ul>
              </li>
              {row.original.fulfillments_kits?.map(
                (fulfillments_kit, kit_index) => {
                  return (
                    <FulfillmentKit
                      fulfillment_kit={fulfillments_kit}
                      onClick={saveKitToFulfillment}
                      key={kit_index}
                    />
                  );
                },
              )}
            </ul>
          </div>

          <div>
            <button
              onClick={() => updateFulfillmentStatus(row.original.id, 'done')}
              className='flex h-10 justify-center items-center relative overflow-hidden px-2 lg:px-5 text-sm min-w-content bg-purple-500 enabled:hover:bg-purple-600 rounded-md border border-solid border-purple-500 text-white transition-all duration-200 ease-in-out delay-75 disabled:opacity-25'
              disabled={
                checkAllLabelsDownloaded(row.original) ||
                checkFulfillmentKitsCompleted(row.original)
              }
            >
              Mark as Done
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default function Fulfillments() {
  return (
    <PageListView
      header='Fulfillments V2'
      tableProps={{
        type: 'fulfillments',
        route: 'fulfillments',
        columns: columns,
        filters: ['pending', 'all', 'done', 'cancelled'],
        defaultFilter: 'pending',
        CustomRowComponent: PendingFulfillmentTableRow,
        showCustomRowComponent: filter => filter === 'pending',
        disabled: filter => filter === 'pending',
        searchable: true,
      }}
    />
  );
}
