import React, { useState } from 'react';

import SamplingTypeBadge from '../../components/SamplingTypeBadge/SamplingTypeBadge';

export default function FulfillmentKit({ fulfillment_kit, onClick }) {
  const [params, setParams] = useState({});

  return (
    <div>
      <li key={fulfillment_kit.id}>
        <ul className='ml-4'>
          {!!fulfillment_kit.expedited_processing && (
            <li>
              <span className='text-md font-serif font-semibold'>
                Expedited Processing
              </span>
            </li>
          )}
          {fulfillment_kit.tracking_number_to_lab && (
            <li>
              <span className='text-sm'>
                Tracking number to lab:{' '}
                {fulfillment_kit.kit_id.tracking_number_to_lab}
              </span>
            </li>
          )}
          {fulfillment_kit.item_name && (
            <li>
              <span className='text-sm'>
                Item name: {fulfillment_kit.item_name}
              </span>
            </li>
          )}
          {(() => {
            const sampling_types = [];
            if (
              !(fulfillment_kit.status || '').startsWith('void_') ||
              !fulfillment_kit.variant
            ) {
              sampling_types.push(fulfillment_kit.sampling_type);
            }
            if (fulfillment_kit.variant?.sampling_type) {
              sampling_types.push(fulfillment_kit.variant.sampling_type);
            }

            return (
              <li>
                <span className='text-sm'>
                  Sampling type:{' '}
                  <SamplingTypeBadge sampling_type={sampling_types} />
                </span>
              </li>
            );
          })()}
          <div className='flex w-full break-words text-left'>
            <span className='text-sm w-1/2'>
              <input
                placeholder='kit_id'
                value={fulfillment_kit.kit_id}
                onChange={e =>
                  setParams(prevState => ({
                    ...prevState,
                    kit_id: e.target.value,
                  }))
                }
                className='flex-auto w-full relative m-0 py-2 px-4 border border-solid border-gray-300 rounded-md text-sm'
                disabled={fulfillment_kit.kit_id ? true : false}
              />
            </span>
            <span className='text-sm w-full'>
              <button
                onClick={() => {
                  onClick(
                    fulfillment_kit.fulfillment_id,
                    fulfillment_kit.id,
                    params.kit_id,
                  );
                  setParams({ kit_id: null });
                }}
                className='flex h-10 justify-center items-center relative overflow-hidden px-2 lg:px-5 text-sm min-w-content bg-purple-500 enabled:hover:bg-purple-600 rounded-md border border-solid border-purple-500 text-white transition-all duration-200 ease-in-out delay-75 disabled:opacity-25'
                disabled={
                  params.kit_id && !fulfillment_kit.kit_id ? false : true
                }
              >
                Save
              </button>
            </span>
          </div>
        </ul>
      </li>
    </div>
  );
}
