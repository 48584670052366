import { useState, useMemo } from 'react';
import ReactSelect from 'react-select';
import { matchSorter } from 'match-sorter';

import './styles.css';

export default function PractitionerDropdown({
  practitioner_id,
  onChange,
  practitioners,
}) {
  const [inputValue, setInputValue] = useState('');

  const selectOptions = useMemo(() => {
    if (practitioners?.length) {
      const practitionerOptions = practitioners.map(practitioner => {
        return {
          label: `${practitioner?.user?.first_name} ${practitioner?.user?.last_name}`,
          value: practitioner.id,
          email: practitioner.user.email,
        };
      });
      return practitionerOptions;
    }
    return [];
  }, [practitioners]);
  const filteredOptions = useMemo(() => {
    if (inputValue) {
      const sorted = matchSorter(selectOptions, inputValue, {
        keys: ['email', 'label'],
      });
      return sorted;
    }
    return selectOptions;
  }, [inputValue, selectOptions]);

  return (
    <ReactSelect
      options={filteredOptions}
      onChange={onChange}
      defaultValue={selectOptions.find(
        option => option?.value === practitioner_id,
      )}
      isSearchable={true}
      isClearable={true}
      components={{
        Option: ({ children, ...props }) => (
          <div {...props} onClick={props.innerProps.onClick}>
            <div className='selectOption'>
              {children}
              <p className='mb-0 f7'>{props.data.email}</p>
            </div>
          </div>
        ),
      }}
      filterOption={() => true}
      onInputChange={inputValue => setInputValue(inputValue)}
    />
  );
}
